// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText, Button, Typography, useTheme, IconButton } from '@mui/material';
import Iconify from '../iconify';
//
import { UploadAvatar, Upload, UploadProps } from '../upload';
import { useLocales } from 'src/locales';
import { useDropzone } from 'react-dropzone';

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
  name: string;
  multiple?: boolean;
}

// ----------------------------------------------------------------------

export function RHFUploadAvatar({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <UploadAvatar
            accept={{
              'image/*': [],
            }}
            error={!!error}
            file={field.value}
            {...other}
          />

          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

// ----------------------------------------------------------------------

interface RHFUploadBoxProps extends Props {
  fileTypes?: string;
  onChangeValue: (files: File[]) => void;
  titleText: string;
  descriptionText: string;
  fileError?: string;
}
interface RHFAddOtherDocumentsProps extends Props {
  fileTypes?: string;
  onChangeValue: (files: File[]) => void;
}
export function RHFAddOtherDocuments({
  name,
  fileTypes,
  onChangeValue,
  ...other
}: RHFAddOtherDocumentsProps) {
  const { control } = useFormContext();
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    multiple: true,
    onDrop: onChangeValue,
    ...other,
  });
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <IconButton
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
              border: `2px dashed ${theme.palette.divider}`,
              borderRadius: theme.shape.borderRadius,
              backgroundColor: 'transparent',
              transition: theme.transitions.create(['background-color', 'box-shadow']),
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
                boxShadow: theme.shadows[2],
              },
              ...(isDragActive && {
                backgroundColor: theme.palette.action.hover,
              }),
            }}
            {...getRootProps()}
          >
            <Iconify icon="ph:plus" />
            <input
              {...getInputProps()}
              onChange={(e) => {
                // field.onChange(e.target.files);
                if (e.target.files) {
                  const filesArray = Array.from(e.target.files);

                  onChangeValue(filesArray);
                }
              }}
            />
          </IconButton>
        );
      }}
    ></Controller>
  );
}

export function RHFUploadBox({
  name,
  fileTypes,
  onChangeValue,
  titleText,
  descriptionText,
  fileError,
  ...other
}: RHFUploadBoxProps) {
  const { control } = useFormContext();
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    multiple: true,
    onDrop: onChangeValue,
    ...other,
  });
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div {...getRootProps()}>
            <Button
              variant="outlined"
              component="label"
              fullWidth
              size="large"
              startIcon={<Iconify icon="ph:file-pdf" />}
              sx={{
                height: 120,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                border: `2px dashed ${theme.palette.text.secondary}`,
                borderRadius: 1,
                backgroundColor: 'transparent',
                transition: theme.transitions.create(['background-color', 'box-shadow']),
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                  boxShadow: theme.shadows[2],
                },
                ...(isDragActive && {
                  backgroundColor: theme.palette.action.hover,
                }),
              }}

              // {...getRootProps()}
            >
              <input
                {...getInputProps()}
                onChange={(e) => {
                  // field.onChange(e.target.files);
                  if (e.target.files) {
                    const filesArray = Array.from(e.target.files);

                    onChangeValue(filesArray);
                  }
                }}
              />
              <Typography variant="h6" component="div" color="text.primary">
                {titleText}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
                {descriptionText}
              </Typography>
              {!!error && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : ''}
                </FormHelperText>
              )}
            </Button>
            {fileError && (
              <Typography variant="caption" color="error">
                {fileError}
              </Typography>
            )}
          </div>
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFUpload({ name, multiple, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        multiple ? (
          <Upload
            multiple
            accept={{ 'image/*': [] }}
            files={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        ) : (
          <Upload
            accept={{ 'image/*': [] }}
            file={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        )
      }
    />
  );
}
