import React, { useEffect, useState } from 'react';
import { useDispatch } from '../../../redux/store';
import { getMissedNotifications } from '../../../redux/slices/pushNotifications';
import { useAuthContext } from 'src/auth/useAuthContext';
import { getToken, getMessaging, isSupported } from 'firebase/messaging';
import app from '../../../firebase';
import axios from 'src/utils/axios';
import { PRODUCTION_ENV } from '../../../config-global';

export default function PushNotifications() {
  const dispatch = useDispatch();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { user } = useAuthContext();

  useEffect(() => {
    async function subscribeUserToPush() {
      try {
        const browserSupported = await isSupported();
        if (user && !isSubscribed && browserSupported && PRODUCTION_ENV == 'true') {
          const messaging = getMessaging(app);

          const token = await getToken(messaging, {
            vapidKey:
              'BIFCtA89CCsQJcPfF6DJ3o4HYyTraEG6fGL12jLnAn0LKpGfQABtX0W1jmc0Iuo8WKQhQ7h6-Huv1Dd4jRkMFz4',
          });

          const isMobile = /Mobi|Android/i.test(navigator.userAgent);
          const deviceInfo = {
            deviceName: navigator.userAgent, // or any other device-specific info
          };
          console.log('Token', token);
          if (token) {
            const response = await axios.post(
              '/api/subscribe',
              {
                user_id: user.uid,
                device: deviceInfo.deviceName,
                is_mobile: isMobile,
                firebase_token: token,
              },
              { withCredentials: true }
            );
            setIsSubscribed(true); // Set the flag to true after successful subscription

            console.log(response);
          }
        }
      } catch (error) {
        if (
          error.code === 'messaging/invalid-argument' ||
          error.code === 'messaging/unregistered'
        ) {
          console.error('Invalid argument provided: ', error.message);
          // Handle invalid argument error, e.g., notify the user or log the error
        } else {
          console.error('Failed to subscribe the user: ', error);
        }
      }
    }
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      subscribeUserToPush();
    }
  }, [user]);

  return <div>{/* Your app components */}</div>;
}
