import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { IPricingState } from '../../@types/pricing'; // Make sure to define this type

const initialState: IPricingState = {
  isLoading: false,
  error: null,
  pricings: [],
  pricing: null,
};

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getPricingSuccess(state, action) {
      state.isLoading = false;
      state.pricing = action.payload;
    },
    getPricingsSuccess(state, action) {
      state.isLoading = false;
      state.pricings = action.payload;
    },
    createPricingSuccess(state, action) {
      state.isLoading = false;
      state.pricing = action.payload;
    },
    updatePricingSuccess(state, action) {
      state.isLoading = false;
      state.pricing = action.payload;
    },
  },
});

export default pricingSlice.reducer;

export function getPricing(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(pricingSlice.actions.startLoading());
    try {
      const response = await axios.get(`/api/pricing/${id}`);
      dispatch(
        response.status === 404
          ? pricingSlice.actions.hasError('Pricing not found')
          : pricingSlice.actions.getPricingSuccess(response.data)
      );
    } catch (error) {
      dispatch(pricingSlice.actions.hasError(error));
    }
  };
}

export function getPricings() {
  return async (dispatch: Dispatch) => {
    dispatch(pricingSlice.actions.startLoading());
    try {
      const response = await axios.get(`/api/pricings`);
      console.log(response);
      dispatch(
        response.status === 404
          ? pricingSlice.actions.hasError('Pricings not found')
          : pricingSlice.actions.getPricingsSuccess(response.data)
      );
    } catch (error) {
      dispatch(pricingSlice.actions.hasError(error));
    }
  };
}

export function createPricing(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch(pricingSlice.actions.startLoading());
    try {
      const response = await axios.post('/api/pricings', data);
      dispatch(
        response.status === 201
          ? pricingSlice.actions.createPricingSuccess(response.data)
          : pricingSlice.actions.hasError('Failed to create pricing')
      );
    } catch (error) {
      dispatch(pricingSlice.actions.hasError(error));
    }
  };
}

export function updatePricing(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch(pricingSlice.actions.startLoading());
    try {
      const response = await axios.put('/api/update_pricing', data);
      dispatch(
        response.status !== 200
          ? pricingSlice.actions.hasError('Failed to update pricing')
          : pricingSlice.actions.updatePricingSuccess(response.data)
      );
    } catch (error) {
      dispatch(pricingSlice.actions.hasError(error));
    }
  };
}

export function deletePricing(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(pricingSlice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/pricing/${id}`);
      if (response.status !== 200) {
        dispatch(pricingSlice.actions.hasError('Failed to delete pricing'));
      }
      // Note: You might want to handle success in some way, e.g., remove the pricing from the state.
    } catch (error) {
      dispatch(pricingSlice.actions.hasError(error));
    }
  };
}
