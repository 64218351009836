import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import overwriteArray from '../../utils/overwriteArray';
import { IInvoice, IInvoiceState } from '../../@types/invoice';
import { ICharge } from '../../@types/charges';
import { getCustomerLatestOrderSuccess, getCustomerSuccess } from './customer';
const initialState: IInvoiceState = {
  isLoading: false,
  error: null,
  invoices: { data: [], page: 0, offset: 0, total: 0 },
  extraInvoices: [],
  customerInvoices: { data: [], page: 0, offset: 0, total: 0 },
  invoice: null,
  old_invoice: null,
};

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET All Invoices
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoice = null;
      state.invoices = {
        data: overwriteArray(state.invoices.data, action.payload.data),
        page: 25 / action.payload.pagination.offset,
        offset: action.payload.pagination.offset,
        total: action.payload.pagination.total,
      };
    },
    // GET  Customer All Invoices
    getCustomerInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoice = null;
      state.customerInvoices = {
        data: action.payload.data,
        page: 25 / action.payload.pagination.offset,
        offset: action.payload.pagination.offset,
        total: action.payload.pagination.total,
      };
    },
    // GET  Customer All Invoices
    getCustomerExtraInvoices(state, action) {
      state.isLoading = false;
      state.invoice = null;
      state.extraInvoices = action.payload.data;
    },
    // GET Specific Invoice by ID
    getInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoice = action.payload.data;
      state.error = null;
    },
    getOldInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.old_invoice = action.payload.data;
      state.error = null;
    },
    // CREATE Invoices
    createInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoice = action.payload.data;
      state.invoices = {
        ...state.invoices,
        total: state.invoices.total + 1,
        data: [action.payload.data, ...state.invoices.data],
      };
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getInvoices(offset: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const offsetStart = offset === 0 ? 0 : offset;
      const response = await axios.get(`/api/order?limit=25&offset=${offset}`, {
        withCredentials: true,
      });
      // console.log(response);
      dispatch(slice.actions.getInvoicesSuccess(response.data));
      return false;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getExtraOrders(id: number, offset: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const offsetStart = offset === 0 ? 0 : offset;
      //console.log(offsetStart);
      const response = await axios.get(`/api/get_extra_orders/${id}?offset=${offset}`, {
        withCredentials: true,
      });
      dispatch(slice.actions.getCustomerExtraInvoices(response));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getCustomerInvoices(id: number, offset: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const offsetStart = offset === 0 ? 0 : offset;
      //console.log(offsetStart);
      const response = await axios.get(`/api/orders_customer/${id}?offset=${offset}`, {
        withCredentials: true,
      });
      // console.log(response);
      dispatch(slice.actions.getCustomerInvoicesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getInvoice(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/order/${id}`, {
        withCredentials: true,
      });
      //console.log(response);
      if (response.status === 404) {
        throw new Error('Invoice not found');
      } else {
        dispatch(slice.actions.getInvoiceSuccess(response));
        //console.log(response.data);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getInvoiceHashed(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/order_hashed/${id}`, {
        withCredentials: true,
      });

      if (response.status === 404) {
        throw new Error('Invoice not found');
      } else {
        dispatch(slice.actions.getInvoiceSuccess(response));
        //console.log(response.data);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getInvoiceOld(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/order_hashed/${id}`, {
        withCredentials: true,
      });

      if (response.status === 404) {
        throw new Error('Invoice not found');
      } else {
        dispatch(slice.actions.getOldInvoiceSuccess(response));
        //console.log(response.data);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function resendEmail(invoice: IInvoice) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/send_order_email',
        { id: invoice.id },
        {
          withCredentials: true,
        }
      );
      //console.log(response);
      if (response.status === 201) {
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function createInvoiceSelf(invoice: IInvoice) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    //console.log(invoice);

    const objectForRequest = {
      pricing_id: invoice.pricing_id,
      total_hours: invoice.total_hours,
      package: invoice.stripe_invoice_id,
      installments: invoice.installments,
      email_sent: invoice.email_sent,
      churn_penalty: invoice.churn_penalty,
      extra_student: invoice.extra_student,
      crm_deal_id: '',
      email: invoice.email,
      customer_id: String(invoice.customer_id),
      name: invoice.name,
      discount: invoice?.discount,
      membership: invoice?.membership,
    };
    // console.log(objectForRequest);

    try {
      const response = await axios.post(
        `/api/create_order_self`,
        { ...objectForRequest },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Error: Could not be created.');
      }
      return response.data.url; // Return true on success
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return '';
    }
  };
}
export function createInvoice(invoice: IInvoice, additionalCharges: ICharge[]) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    //console.log(invoice);

    const objectForRequest = {
      pricing_id: invoice.pricing_id,
      total_hours: invoice.total_hours,
      package: invoice.stripe_invoice_id,
      installments: invoice.installments,
      email_sent: invoice.email_sent,
      churn_penalty: invoice.churn_penalty,
      extra_student: invoice.extra_student,
      crm_deal_id: '',
      email: invoice.email,
      customer_id: String(invoice.customer_id),
      name: invoice.name,
      discount: invoice?.discount,
      additional_discount_code:
        invoice?.additional_discount === 0 ? '' : invoice.additional_discount, // if no discount added give empty string for request
      membership: invoice?.membership,
      sales_rep: invoice.sales_rep,
      charge_types: additionalCharges.map((value) => {
        return {
          charge_type_id: value.charge_type_id,
          quantity: value.quantity,
        };
      }),
    };
    // console.log(objectForRequest);

    try {
      const response = await axios.post(
        `/api/add_order`,
        { ...objectForRequest },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Error: Could not be created.');
      }
      return response.data.order_id; // Return true on success
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createCustomInvoice(invoice: IInvoice) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());

    const objectForRequest = {
      total_hours: invoice.total_hours,
      package:
        invoice.stripe_invoice_id.split('-')[1] === '0'
          ? `${invoice.stripe_invoice_id.split('-')[0]}-000`
          : invoice.stripe_invoice_id,
      installments: invoice.installments,
      email_sent: invoice.email_sent,
      churn_penalty: invoice.churn_penalty,
      extra_student: invoice.extra_student,
      sales_rep: invoice.sales_rep,
      crm_deal_id: '',
      email: invoice.email,
      name: invoice.name,
      discount: invoice?.discount,
    };

    try {
      const response = await axios.post(
        `/api/add_order`,
        { ...objectForRequest },
        {
          withCredentials: true,
        }
      );

      //console.log(response);
      if (response.status === 404) {
        throw new Error('Invoice not found');
      }

      return response;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function updateMembership(invoice: IInvoice) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const objectForRequest = {
      uid: invoice.id,
      pricing_id: invoice.pricing_id,
      total_hours: invoice.total_hours,
      package: invoice.stripe_invoice_id,
      installments: invoice.installments,
      email_sent: invoice.email_sent,
      churn_penalty: invoice.churn_penalty,
      extra_student: invoice.extra_student,
      crm_deal_id: '',
      discount: invoice?.discount,
      automatic_renewal: invoice.automatic_renewal,
      switch: invoice?.switch,
    };
    // console.log(objectForRequest);
    try {
      const response = await axios.put(
        `/api/update_membership`,
        { ...objectForRequest },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Invoice not found');
      } else {
        dispatch(slice.actions.getInvoiceSuccess(response));
        //console.log(response);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function switchMembership(invoice: IInvoice) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const objectForRequest = {
      uid: invoice.id,
      total_hours: invoice.total_hours,
      package: invoice.stripe_invoice_id,
      installments: invoice.installments,
      email_sent: invoice.email_sent,
      churn_penalty: invoice.churn_penalty,
      extra_student: invoice.extra_student,
      pricing_id: invoice.pricing_id,
      crm_deal_id: '',
      discount: invoice?.discount,
    };
    try {
      const response = await axios.put(
        `/api/switch_membership`,
        { ...objectForRequest },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Invoice not found');
      } else {
        // console.log(response);
        return response.data.url;
      }
    } catch (error) {
      // console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return errorMessage;
    }
  };
}
export function updateInvoice(invoice: IInvoice) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    // console.log(invoice);
    const objectForRequest = {
      uid: invoice.id,
      status: invoice.status,
      email_sent: invoice.email_sent,
      switch: invoice.switch,
      churn_penalty: invoice.churn_penalty,
      expiration_date: invoice.expiration_date,
      renewal_date: invoice.renewal_date,
      automatic_renewal: invoice.automatic_renewal,
    };
    try {
      if (invoice.status === 'void') {
        const response = await axios.put(
          `/api/void_order`,
          { id: invoice.id },
          {
            withCredentials: true,
          }
        );
        if (response.status === 404) {
          throw new Error('Invoice not found');
        } else {
          dispatch(slice.actions.getInvoiceSuccess(response));
          //console.log(response);
          return true;
        }
      } else {
        const response = await axios.put(
          `/api/update_orders`,
          { ...objectForRequest },
          {
            withCredentials: true,
          }
        );
        if (response.status === 404) {
          throw new Error('Invoice not found');
        } else {
          dispatch(slice.actions.getInvoiceSuccess(response));
          //console.log(response);
          return true;
        }
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function resetAiToken(customer_id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(order_id);
      const response = await axios.post(
        `/api/reorder_ai_tokens`,
        { customer_id: customer_id },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Balance not found');
      } else {
        // console.log(response);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function cancelAiTokens(customer_id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(order_id);
      const response = await axios.post(
        `/api/cancel_ai_tokens`,
        { customer_id: customer_id },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Balance not found');
      } else {
        // console.log(response);
        // update state of customer
        dispatch(getCustomerSuccess(response));
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function orderAiTokens(customer_id: string | number, quantity: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(order_id);
      const response = await axios.post(
        `/api/order_tokens`,
        { customer_id: customer_id, quantity: quantity },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Balance not found');
      } else {
        // console.log(response);
        window.location.replace(response.data.url);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function orderExtraCredits(order_id: string | number, quantity: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(order_id);
      const response = await axios.post(
        `/api/create_extra_credits`,
        { order_id: order_id, quantity: quantity },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Balance not found');
      } else {
        // console.log(response);
        window.location.replace(response.data.url);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function unpauseInvoice(order: IInvoice) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(order);
      const response = await axios.post(
        `/api/unpause_order`,
        { order_id: order.id },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Balance not found');
      } else {
        dispatch(getCustomerLatestOrderSuccess(response));
        dispatch(slice.actions.getInvoiceSuccess(response));
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function pauseInvoice(order: IInvoice, pauseValue: boolean, pauseDuration: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(order);
      const response = await axios.put(
        `/api/update_membership`,
        { uid: order.id, paused: pauseValue, pause_duration: pauseDuration },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Balance not found');
      } else {
        dispatch(getCustomerLatestOrderSuccess(response));
        dispatch(slice.actions.getInvoiceSuccess(response));
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function extendPause(order: IInvoice, pauseDuration: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(order);
      const response = await axios.put(
        `/api/extend_pause`,
        { order_id: order.id, extend_length: pauseDuration },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Balance not found');
      } else {
        dispatch(getCustomerLatestOrderSuccess(response));
        dispatch(slice.actions.getInvoiceSuccess(response));
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function switchMembershipAdmin(
  invoice: IInvoice,
  penaltyOverride?: boolean,
  periodsAfterCurrent?: number
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());

    const objectForRequest = {
      uid: invoice.id,
      pricing_id: invoice.pricing_id,
      total_hours: invoice.total_hours,
      unit_price: invoice.unit_price,
      package: invoice.stripe_invoice_id,
      expiration_date: invoice.expiration_date,
      installments: invoice.installments,
      crm_deal_id: '',
      email: invoice.email,
      name: invoice.name,
      status: invoice.status,
      email_sent: invoice.email_sent,
      penalty_value: penaltyOverride,
      periods_after_current: periodsAfterCurrent,
      churn_penalty: invoice.churn_penalty,
      paused: invoice.paused,
      discount: invoice.discount || '',
      extra_student: invoice.extra_student,
      total_price: invoice.total_price,
    };

    try {
      const response = await axios.put(`/api/admin/switch_membership_admin`, objectForRequest, {
        withCredentials: true,
      });

      if (response.status === 404) {
        throw new Error('Invoice not found');
      }
      return response.data.url;
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error.errors.json._schema[0];
      } else if (error?.messages?.json?._schema) {
        errorMessage = error.messages.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error.errors.json[Object.keys(error.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return errorMessage;
    }
  };
}
