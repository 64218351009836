import { Navigate, useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';

// auth
import RoleBasedGuard from '../auth/RoleBasedGuard';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';

import {
  Page404,
  PageDashboard,
  // Memberships
  CustomerMembershipPage,
  ViewMembershipPage,
  MembershipPage,
  MembershipConfirmationPage,
  SwitchMembershipPage,
  SwitchMembershipPageSlider,
  // Auth
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  ConfirmationPage,
  FreemiumConfirmationPage,
  ResetPasswordPage,
  Thanks,
  // AI,
  AiPage,
  AiEmptyPage,
  // Note Editor
  NoteHomePage,
  NoteEditorPage,
  // Tutor
  PageTutorList,
  TutorProfilePage,
  TutorCreatePage,
  TutorStudentsPage,
  TutorEarningsPage,
  TutorCoursesDetails,
  // Customer
  PageCustomerList,
  CustomerProfilePage,
  CustomerCreatePage,
  CustomerTeachersPage,
  CustomerStudentsPage,
  // Student
  PageStudentList,
  StudentProfilePage,
  StudentCreatePage,
  StudentTeachersPage,
  StudentReportPage,
  // Lessons
  PageLessonsList,
  LessonPage,
  LessonDetailsPage,
  // Lesson requests
  LessonRequestsPage,
  //email
  SendEmailPage,

  // Invoices
  PageInvoiceList,
  PageCustomerInvoicesList,
  PageInvoice,
  PageCreateEditInvoice,
  PageCreateCustomInvoice,
  PayInvoicePage,
  // Transactions
  PageTransactionList,
  PageCustomerTransactionsList,
  PageViewTransaction,
  PageEditCreateTransaction,
  //Balances
  PageBalancesList,
  PageBalance,
  // Payslips
  PagePayslipsList,
  PagePayslipDetails,
  //Leaderboard
  // PageLeaderboard,
  //Badge
  // PageBages,
  PageParticipantDetails,
  PageTutorCourses,
  // PageChat,
} from './elements';

import Calendarpage from '../pages/dashboard/calendar/CalendarPage';
import { useAuthContext } from 'src/auth/useAuthContext';

type Props = {
  setReturnForStudent: (value: boolean) => void;
  setUrlForLesson: (value: string) => void;
  urlForLesson: string;
  setLessonId: (value: string) => void;
};

const useSentryRoutes = wrapUseRoutes(useRoutes);

// ----------------------------------------------------------------------

export default function Router({
  urlForLesson,
  setReturnForStudent,
  setUrlForLesson,
  setLessonId,
}: Props) {
  const { user } = useAuthContext();
  return useSentryRoutes([
    {
      path: 'ai',
      children: [
        {
          path: 'tutor',
          element: (
            <GuestGuard redirectTo="/ai">
              <AiEmptyPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'thanks',
          element: (
            <GuestGuard>
              <Thanks />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          children: [
            {
              element: (
                <GuestGuard>
                  <RegisterPage />
                </GuestGuard>
              ),
              index: true,
            },
            {
              element: (
                <GuestGuard>
                  <RegisterPage />
                </GuestGuard>
              ),
              path: 'step2',
            },
          ],
        },
        {
          path: 'confirmation',
          element: (
            <GuestGuard>
              <ConfirmationPage />
            </GuestGuard>
          ),
        },
        {
          path: 'confirm',
          element: <FreemiumConfirmationPage />,
        },
        {
          path: 'forgot',
          element: (
            <GuestGuard>
              <ForgotPasswordPage />
            </GuestGuard>
          ),
        },
        {
          path: 'reset',
          element: (
            <GuestGuard>
              <ResetPasswordPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'invoice',
      children: [
        {
          path: 'pay/:hashed_id',
          element: <PayInvoicePage />,
        },
      ],
    },
    {
      path: 'membership',
      children: [
        { element: <MembershipPage />, index: true },
        { element: <MembershipPage />, path: ':id' },
        {
          path: 'order/:customer_id',
          element: (
            // <RoleBasedGuard hasContent roles={['admin', 'customer']}>
            <CustomerMembershipPage />
            // </RoleBasedGuard>
          ),
        },
        { element: <MembershipConfirmationPage />, path: 'confirmation/:id' },
      ],
    },
    // ALL APP ROUTES
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <RoleBasedGuard
              hasContent
              markets={['1', '2']}
              roles={['admin', 'teacher', 'customer', 'student']}
              freemium
            >
              {' '}
              <Navigate to={user?.freemium_signup ? '/ai' : '/dashboard'} />
            </RoleBasedGuard>
            // {/* </RoleBasedGuard> */}
          ),
          index: true,
        },
        {
          path: 'dashboard',
          element: (
            <RoleBasedGuard
              hasContent
              markets={['1', '2']}
              roles={['admin', 'teacher', 'customer', 'student']}
            >
              <PageDashboard />{' '}
            </RoleBasedGuard>
          ),
        },
        {
          path: 'calendar',
          element: (
            <RoleBasedGuard
              hasContent
              markets={['1', '2']}
              roles={['admin', 'teacher', 'customer', 'student']}
            >
              <Calendarpage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'student',
          children: [
            { element: <PageStudentList />, index: true },
            { path: ':id', element: <StudentProfilePage /> },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <StudentCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'teacherList',
              element: (
                <RoleBasedGuard hasContent roles={['student']}>
                  <StudentTeachersPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'membership',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['customer', 'admin']}>
                  <SwitchMembershipPageSlider />
                </RoleBasedGuard>
              ),
              path: 'switch/:id',
            },
            {
              element: (
                <RoleBasedGuard hasContent roles={['customer']}>
                  <ViewMembershipPage />
                </RoleBasedGuard>
              ),
              path: 'view/:id',
            },
          ],
        },
        {
          path: 'ai',
          children: [
            {
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['customer', 'admin', 'superadmin', 'student', 'teacher']}
                  freemium
                >
                  <AiPage />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        // {
        //   path: 'notes',
        //   children: [
        //     {
        //       element: (
        //         <RoleBasedGuard
        //           hasContent
        //           roles={['customer', 'teacher', 'admin', 'superadmin', 'student']}
        //           markets={['1', '2']}
        //         >
        //           <NoteHomePage />
        //         </RoleBasedGuard>
        //       ),
        //       index: true,
        //     },
        //   ],
        // },
        {
          path: 'document',
          children: [
            { path: 'home', element: <NoteHomePage /> },
            {
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['customer', 'teacher', 'admin', 'superadmin', 'student']}
                  markets={['1', '2']}
                >
                  <NoteEditorPage />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: 'family', // Aka Customers. They can either be family or independent.
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageCustomerList />
                </RoleBasedGuard>
              ),
              index: true,
            },
            { path: ':id', element: <CustomerProfilePage /> },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'customer']}>
                  <CustomerProfilePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard roles={['admin']}>
                  <CustomerCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'teacherList',
              element: (
                <RoleBasedGuard roles={['customer']}>
                  <CustomerTeachersPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'studentList',
              element: (
                <RoleBasedGuard roles={['customer']}>
                  <CustomerStudentsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'courses',
          element: (
            <RoleBasedGuard roles={['teacher', 'admin']} markets={['1']}>
              <PageTutorCourses />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'course/:hashed_id',
          element: (
            <RoleBasedGuard roles={['admin', 'teacher']} markets={['1']}>
              <TutorCoursesDetails />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'tutor',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageTutorList />
                </RoleBasedGuard>
              ),
              index: true,
            },
            { path: ':id', element: <TutorProfilePage /> },
            { path: ':id/edit', element: <Page404 /> },
            {
              path: 'new',
              element: (
                <RoleBasedGuard roles={['admin']}>
                  <TutorCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'studentList',
              element: (
                <RoleBasedGuard roles={['teacher']}>
                  <TutorStudentsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'lesson-requests/:id',
          element: (
            <RoleBasedGuard roles={['teacher']}>
              <LessonRequestsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'lesson',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'teacher', 'student', 'customer']}>
                  <PageLessonsList />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':id',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'customer', 'student', 'teacher']}>
                  <LessonPage
                    setLessonId={setLessonId}
                    goBackStudent={setReturnForStudent}
                    urlForLesson={urlForLesson}
                    setUrlForLesson={setUrlForLesson}
                    setRedirectionUrl={(value: string) => {
                      // console.log(value);
                    }}
                  />
                </RoleBasedGuard>
              ),
            }, // route to connect to lesson space
            {
              path: ':id/details',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'customer', 'teacher', 'student']}>
                  <LessonDetailsPage />
                </RoleBasedGuard>
              ),
            }, // route for the lesson recording
            { path: ':id/edit', element: <Page404 /> },
            { path: 'new', element: <Page404 /> },
          ],
        },
        {
          path: 'earnings/:id',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'teacher']}>
                  <TutorEarningsPage />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },

        {
          path: 'studentReport',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <StudentReportPage />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: 'email',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <SendEmailPage />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },

        {
          path: 'invoice',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageInvoiceList />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':customer_id',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageCustomerInvoicesList />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':customer_id/:id',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':customer_id/:id/edit',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageCreateEditInvoice />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageCreateEditInvoice />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'custom',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageCreateCustomInvoice />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'payslip',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PagePayslipsList />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':tutor_id/:id',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'teacher']}>
                  <PagePayslipDetails />
                </RoleBasedGuard>
              ),
            }, // This is the page to see the individual payslip.
          ],
        },
        {
          path: 'balances',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageBalancesList />
                </RoleBasedGuard>
              ),
              index: true,
            }, // See a list of all customers' balances.
            {
              path: ':customer_id',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageBalance />
                </RoleBasedGuard>
              ),
            }, // this page to see the customer's balance
          ],
        },
        // {
        //   path: 'leaderboard',
        //   children: [
        //     {
        //       element: (
        //         <RoleBasedGuard hasContent roles={['admin']}>
        //           {/* <PageLeaderboard />  */}
        //         </RoleBasedGuard>
        //       ),
        //       index: true,
        //     }, // leaderboard
        //   ],
        // },
        {
          path: 'refer',
          children: [
            {
              path: ':user_id',
              element: (
                <RoleBasedGuard hasContent roles={['teacher', 'customer', 'student']}>
                  <PageParticipantDetails />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'transaction',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageTransactionList />
                </RoleBasedGuard>
              ),
              index: true,
            }, // all transactions
            {
              path: ':customer_id',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'customer']}>
                  <PageCustomerTransactionsList />
                </RoleBasedGuard>
              ),
            }, // this page to see the specific customer's transactions
            {
              path: ':customer_id/:id',
              element: (
                <RoleBasedGuard hasContent roles={['admin', 'customer']}>
                  <PageViewTransaction />
                </RoleBasedGuard>
              ),
            }, // this page to see the specific transaction
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageEditCreateTransaction />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageEditCreateTransaction />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'education',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageInvoiceList />
                </RoleBasedGuard>
              ),
              index: true,
            }, // List of all
            { path: ':id/edit', element: <Page404 /> },
            { path: 'new', element: <Page404 /> },
          ],
        },
        {
          path: 'school',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageInvoiceList />
                </RoleBasedGuard>
              ),
              index: true,
            }, // List of all
            { path: ':id/edit', element: <Page404 /> },
            { path: 'new', element: <Page404 /> },
          ],
        },
        {
          path: 'subjects',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageInvoiceList />
                </RoleBasedGuard>
              ),
              index: true,
            }, // List of all
            { path: ':id/edit', element: <Page404 /> },
            { path: 'new', element: <Page404 /> },
          ],
        },
        {
          path: 'languages',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageInvoiceList />
                </RoleBasedGuard>
              ),
              index: true,
            }, // List of all
            { path: ':id/edit', element: <Page404 /> },
            { path: 'new', element: <Page404 /> },
          ],
        },
        {
          path: 'interests',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageInvoiceList />
                </RoleBasedGuard>
              ),
              index: true,
            }, // List of all
            { path: ':id/edit', element: <Page404 /> },
            { path: 'new', element: <Page404 /> },
          ],
        },
        {
          path: 'qualifications',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageInvoiceList />
                </RoleBasedGuard>
              ),
              index: true,
            }, // List of all
            { path: ':id/edit', element: <Page404 /> },
            { path: 'new', element: <Page404 /> },
          ],
        },
        {
          path: 'programs',
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PageInvoiceList />
                </RoleBasedGuard>
              ),
              index: true,
            }, // List of all
            { path: ':id/edit', element: <Page404 /> },
            { path: 'new', element: <Page404 /> },
          ],
        },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <PageChat />, index: true },
        //     { path: 'new', element: <PageChat /> },
        //     { path: ':conversationKey', element: <PageChat /> },
        //   ],
        // },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
