import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import {
  updateFoldersAfterNoteCreation,
  updateFoldersAfterNoteUpdate,
  updateFoldersAfterNoteDeletion,
} from './folders';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { INote, INoteState } from 'src/@types/note';
import { Editor } from '@tiptap/react';
import { store } from 'src/redux/store';
import { StreamMessage } from 'src/sections/@dashboard/notes/TipTapEditor/extensions/StreamingUtils/StreamingUtils';
import { useAuthContext } from 'src/auth/useAuthContext';
import {
  processAiStream,
  processAiInsert,
  handleStreamingContent,
  sanitizeContent,
  getSelectedText,
} from 'src/sections/@dashboard/notes/TipTapEditor/lib/ai-utils';

const initialState: INoteState = {
  isLoading: false,
  isSlashLoading: false,
  isStreamingContent: false,
  isAiActionLoading: false,
  error: null,
  notes: [],
  note: null,
  improvements: [],
};

const slice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    // SET note to null
    setNoteNull(state) {
      state.note = null;
    },
    // STOPS LOADING
    stopLoading(state) {
      state.isLoading = false;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // LOADS POTENTIAL IMPROVEMENTS
    generateImprovementsSuccess(state, action) {
      state.error = null;
      state.improvements = [...action.payload];
    },
    // POST Note to database
    postNoteSuccess(state, action) {
      console.log('postNoteSuccess - note_type:', action.payload.note_type);
      state.isLoading = false;
      state.note = {
        ...action.payload,
        editorProps: {
          attributes: {
            'data-note-type': action.payload.note_type || 'notes',
          },
        },
      };
      console.log('postNoteSuccess - state.note:', state.note);
      state.error = null;
      state.notes = [action.payload, ...state.notes];
    },
    // GET All Notes
    getNotesSuccess(state, action) {
      state.isLoading = false;
      // Usually uses ...state.notes, ...action.payloard
      // Creates a weird double load, so removed ...state.notes for now
      state.notes = action.payload;
    },
    // GET Specific Note by ID
    getNoteSuccess(state, action) {
      console.log('getNoteSuccess - note_type:', action.payload.data.note_type);
      state.isLoading = false;
      state.note = {
        ...action.payload.data,
        editorProps: {
          attributes: {
            'data-note-type': action.payload.data.note_type || 'notes',
          },
        },
      };
      console.log('getNoteSuccess - state.note:', state.note);
      state.error = null;
    },
    // PUT Updates onto note via ID
    getUpdateSuccess(state, action) {
      state.note = {
        ...action.payload,
        editorProps: {
          attributes: {
            'data-note-type': action.payload.note_type || 'notes',
          },
        },
      };
      state.notes = state.notes.map((note: INote) => {
        if (parseInt(note.id.toString() || '') === parseInt(action.payload.id)) {
          return {
            ...action.payload,
            editorProps: {
              attributes: {
                'data-note-type': action.payload.note_type || 'notes',
              },
            },
          };
        }
        return note;
      });
    },
    // DELETE Note via ID
    deleteNoteSuccess(state, action) {
      // Combine notes from both state and folder
      const allNotes = [...state.notes.filter((v) => v.id !== action.payload.old_note_id)];
      state.isLoading = false;
      state.error = null;
      state.notes = allNotes;
      state.note = action.payload.data;
    },
    moveNoteToFolderSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      // Update notes array with the moved note
      state.notes = state.notes.map((note: INote) => {
        if (note.id === action.payload.id) {
          return action.payload;
        }
        return note;
      });
    },
    startSlashLoading(state) {
      state.isSlashLoading = true;
      state.error = null;
    },
    stopSlashLoading(state) {
      state.isSlashLoading = false;
      state.error = null;
    },
    setAiLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    startStreamingContent(state) {
      state.isStreamingContent = true;
      state.error = null;
    },
    stopStreamingContent(state) {
      state.isStreamingContent = false;
      state.error = null;
    },
    startAiActionLoading(state) {
      state.isAiActionLoading = true;
      state.error = null;
    },
    stopAiActionLoading(state) {
      state.isAiActionLoading = false;
      state.error = null;
    },
  },
});
console.log(slice);
export const { startLoading, stopLoading, deleteNoteSuccess, startSlashLoading, stopSlashLoading } =
  slice.actions;
export default slice.reducer;
export function getJwtNoteToken() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get(`/api/generate_jwt_token_tiptap`, {
        withCredentials: true,
      });
      console.log(response);
      if (response.status === 403) {
        throw new Error('Access denied');
      } else {
        dispatch(slice.actions.stopLoading());
        localStorage.setItem('aiTipTapToken', response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function startNoteEditor(user_id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.stopLoading());
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/start_note_editor`,
        { user_id: user_id },
        {
          withCredentials: true,
        }
      );
      // console.log(response);
      if (response.status === 404) {
        throw new Error('Note not found');
      } else {
        console.log(response);
        dispatch(slice.actions.getNoteSuccess(response));
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getNotes(user_id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/fetch_notes/${user_id}`, {
        withCredentials: true,
      });
      // console.log(response);
      dispatch(slice.actions.getNotesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getNote(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/fetch_note/${id}`, {
        withCredentials: true,
      });
      // console.log(response);
      if (response.status === 404) {
        throw new Error('Order not found');
      } else {
        console.log(response);
        dispatch(slice.actions.getNoteSuccess(response));
        // console.log(response.data);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createNote(user_id: number, folderId?: number, noteType = 'notes') {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('createNote - noteType param:', noteType);
      const response = await axios.post(`/api/create_note`, {
        user_id: user_id,
        folder_id: folderId,
        name: '',
        is_public: false,
        output_field: ``,
        note_type: noteType,
      });

      console.log('createNote - response data:', response.data);

      // Ensure note_type is preserved from the request, not the response
      const noteData = {
        ...response.data,
        note_type: noteType, // Force the noteType from the request
      };

      if (response.data.folder_id) {
        dispatch(slice.actions.stopLoading());
        dispatch(updateFoldersAfterNoteCreation(noteData));
        dispatch(
          slice.actions.getNoteSuccess({
            ...response,
            data: noteData,
          })
        );
      } else {
        dispatch(slice.actions.postNoteSuccess(noteData));
      }
      return response.data;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function saveNote(id: string | null, name: string, output_field: string) {
  return async (dispatch: Dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/update_note`, {
        id: id,
        name: name,
        output_field: output_field,
      });
      // console.log(response);
      if (response.data.folder_id) {
        dispatch(updateFoldersAfterNoteUpdate(response.data));
      } else {
        dispatch(slice.actions.getUpdateSuccess(response.data));
      }
      dispatch(slice.actions.stopLoading());

      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function publicizeNote(id: string | null) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/publicize_note`, {
        id: id,
        is_public: true,
      });
      // console.log(response);
      dispatch(slice.actions.getNotesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function deleteNote(note: INote) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/delete_note/${note.id}`, {});
      // console.log(response);
      if (note.folder_id) {
        dispatch(updateFoldersAfterNoteDeletion(note.id));
      }
      dispatch(slice.actions.deleteNoteSuccess({ data: response.data, old_note_id: note.id }));

      dispatch(slice.actions.stopLoading());
      return response.data;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function exportNote(htmlContent: string, fileType: string, fileName: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const contentType =
        fileType === 'pdf'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

      const response = await axios.post(
        `/api/export_note`,
        {
          htmlContent: `
            <!DOCTYPE html>
            <html>
              <head>
                <meta charset="utf-8">
                <title>${fileName}</title>
              </head>
              <body>
                ${htmlContent}
              </body>
            </html>
          `,
          fileType: fileType,
        },
        {
          responseType: 'arraybuffer', // Changed from 'blob' to 'arraybuffer'
          headers: {
            'Content-Type': 'application/json',
            withCredentials: true,
            Accept: contentType,
          },
        }
      );

      // Create blob from array buffer with proper type
      const blob = new Blob([response.data], { type: contentType });
      const fileExtension = fileType === 'pdf' ? '.pdf' : '.docx';
      const safeFileName = fileName.trim().replace(/\.$/, '') || 'toptutors_export';
      const fullFileName = `${safeFileName}${fileExtension}`;

      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fullFileName;

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(slice.actions.stopLoading());
      return true;
    } catch (error) {
      console.error('Export error:', error);
      const errorMessage = error?.message || 'An error occurred during export';
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createAINote(
  user_id: number | null,
  subject: string,
  classLevel: string,
  topic: string,
  files: File[],
  style: string,
  onStreamData: (data: string) => void,
  onSetTitle: (title: string) => void
) {
  return async (dispatch: Dispatch) => {
    // dispatch(slice.actions.startLoading());
    return new Promise((resolve, reject) => {
      // Build URL with query parameters
      const baseUrl = process.env.REACT_APP_HOST_API_KEY || '';
      const url =
        `${baseUrl}/api/create_ai_note?` +
        `&subject=${encodeURIComponent(subject)}` +
        `&classLevel=${encodeURIComponent(classLevel)}` +
        `&topic=${encodeURIComponent(topic)}` +
        `&style=${encodeURIComponent(style)}` +
        (user_id ? `&user_id=${encodeURIComponent(String(user_id))}` : '');

      const eventSource = new EventSourcePolyfill(url, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
        },
      });

      let noteContent = '';
      let newTitle = '';
      const timeoutDuration = 100000; // 100 seconds
      let timeoutId: ReturnType<typeof setTimeout> | null = null;

      const timeoutHandler = () => {
        console.log('EventSource request timed out');
        eventSource.close();
        dispatch(slice.actions.hasError('Request timed out'));
        reject(new Error('Request timed out'));
      };

      const clearEventSourceTimeout = () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
      };

      eventSource.onmessage = (event) => {
        clearEventSourceTimeout();

        if (event.data === 'ENDSTREAM') {
          dispatch(slice.actions.stopLoading());
          eventSource.close();
          resolve(noteContent);
        } else if (event.data === 'PAUSE') {
          noteContent += '\n';
        } else if (event.data.includes('initial_data')) {
          const cleanData = event.data.replace(/data:\s*$/, '');
          try {
            const parsedData = JSON.parse(cleanData);
            newTitle = parsedData.data;
            if (onSetTitle) {
              onSetTitle(newTitle);
            }
          } catch (error) {
            console.error('Error parsing initial data:', error);
            console.log('Raw data received:', event.data);
          }
        } else {
          noteContent += event.data;
          if (onStreamData) {
            onStreamData(noteContent);
          }
          timeoutId = setTimeout(timeoutHandler, timeoutDuration);
        }
      };

      eventSource.onerror = (error) => {
        dispatch(slice.actions.stopLoading());
        console.error('EventSource encountered an error:', error);
        clearEventSourceTimeout();
        eventSource.close();
        dispatch(slice.actions.hasError('Error generating note content'));
        reject(new Error('Error with message'));
      };

      // Start initial timeout
      timeoutId = setTimeout(timeoutHandler, timeoutDuration);
    });
  };
}

export function createAISummary(
  user_id: number | null,
  files: File[],
  style: string,
  onStreamData: (data: string) => void,
  onSetTitle: (title: string) => void
) {
  return async (dispatch: Dispatch) => {
    try {
      const formData = new FormData();
      if (user_id) {
        formData.append('user_id', user_id.toString());
      }
      for (const file of files) {
        formData.append('file', file);
      }
      formData.append('style', style);

      dispatch(slice.actions.startLoading());

      // Upload files first
      await axios.post('/api/upload_files_for_summary', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      // Then establish SSE connection with file references
      const baseUrl = process.env.REACT_APP_HOST_API_KEY || '';
      const params = new URLSearchParams({
        user_id: user_id?.toString() || '',
        style,
      });

      let retryCount = 0;
      const MAX_RETRIES = 3;

      const createEventSource = () => {
        const eventSource = new EventSourcePolyfill(`${baseUrl}/api/create_ai_summary?${params}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          withCredentials: true,
          heartbeatTimeout: 300000, // 5 minutes
        });
        return eventSource;
      };

      let summaryContent = '';
      let newTitle = '';
      const timeoutDuration = 300000; // 5 minutes
      let timeoutId: ReturnType<typeof setTimeout> | null = null;
      let eventSource = createEventSource();

      return new Promise((resolve, reject) => {
        const timeoutHandler = () => {
          console.log('EventSource request timed out');
          eventSource.close();

          if (retryCount < MAX_RETRIES) {
            retryCount++;
            console.log(`Retrying connection (${retryCount}/${MAX_RETRIES})`);
            eventSource = createEventSource();
            setupEventHandlers();
          } else {
            dispatch(slice.actions.hasError('Request timed out after retries'));
            reject(new Error('Request timed out after retries'));
          }
        };

        const clearEventSourceTimeout = () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
          }
        };

        const setupEventHandlers = () => {
          eventSource.onmessage = (event) => {
            clearEventSourceTimeout();
            retryCount = 0; // Reset retry count on successful message

            if (event.data === 'ENDSTREAM') {
              dispatch(slice.actions.stopLoading());
              eventSource.close();
              resolve(summaryContent);
            } else if (event.data === 'PAUSE') {
              summaryContent += '\n';
            } else if (event.data.includes('initial_data')) {
              try {
                const parsedData = JSON.parse(event.data);
                newTitle = parsedData.data;
                if (onSetTitle) {
                  onSetTitle(newTitle);
                }
              } catch (error) {
                console.error('Error parsing initial data:', error);
              }
            } else {
              summaryContent += event.data;
              if (onStreamData) {
                dispatch(slice.actions.stopLoading());
                onStreamData(summaryContent);
              }
            }

            timeoutId = setTimeout(timeoutHandler, timeoutDuration);
          };

          eventSource.onerror = (error) => {
            console.error('EventSource encountered an error:', error);
            clearEventSourceTimeout();
            eventSource.close();

            if (retryCount < MAX_RETRIES) {
              retryCount++;
              console.log(`Retrying connection (${retryCount}/${MAX_RETRIES})`);
              setTimeout(() => {
                eventSource = createEventSource();
                setupEventHandlers();
              }, 1000 * retryCount); // Exponential backoff
            } else {
              dispatch(slice.actions.hasError('Error generating summary after retries'));
              reject(new Error('Error with EventSource after retries'));
            }
          };
        };

        setupEventHandlers();
        timeoutId = setTimeout(timeoutHandler, timeoutDuration);
      });
    } catch (error) {
      console.error(error);
      let errorMessage = '';
      if (error?.errors?.json?._schema) {
        errorMessage = error.errors.json._schema[0];
      } else if (error?.messages?.json?._schema) {
        errorMessage = error.messages.json._schema[0];
      } else {
        errorMessage = error?.message || 'An error occurred';
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createAIEssay(
  user_id: number | null,
  subject: string,
  classLevel: string,
  topic: string,
  details: string
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const formData = new FormData();
      if (user_id) {
        formData.append('user_id', user_id.toString());
      }
      formData.append('subject', subject);
      formData.append('classLevel', classLevel);
      formData.append('topic', topic);
      formData.append('details', details);

      const response = await axios.post('/api/create_ai_essay', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        dispatch(slice.actions.postNoteSuccess(response.data));
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createDocument(note_id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/create_document`, {
        note_id: note_id,
        // needs to somehow generate token
        storage_string: 'temp string',
      });
      console.log(response);
      dispatch(slice.actions.getNotesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function moveNoteToFolder({ noteId, folderId }: { noteId: number; folderId: number }) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(noteId, folderId);
      const response = await axios.put(
        `/api/move_note_to_folder/${noteId}`,
        { folder_id: folderId },
        { withCredentials: true }
      );

      dispatch(slice.actions.moveNoteToFolderSuccess(response.data));

      // Optionally refresh folders if needed
      if (response.data.folder_id) {
        dispatch(updateFoldersAfterNoteUpdate(response.data));
      }

      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

// Export the new async thunk
export function getAIDocumentResponse(prompt: string, documentContext: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        `/api/document_ai_assist`,
        {
          prompt,
          context: documentContext,
        },
        {
          withCredentials: true,
        }
      );

      console.log(response.data);
      return response.data; // Just return the response data
    } catch (error) {
      console.error(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

// Generic slash command executor

export function aiImproveContent(htmlContent: string, goalImprovement: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startSlashLoading());
    try {
      const response = await axios.post(`/api/improve_note`, {
        html_content: htmlContent,
        goal_improvement: goalImprovement,
      });
      console.log(response);
      dispatch(slice.actions.generateImprovementsSuccess(response.data));
      dispatch(slice.actions.stopSlashLoading());
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      dispatch(slice.actions.stopSlashLoading());
      return false;
    }
  };
}

/**
 * Main function to execute slash commands, handles streaming responses, handles all note types (notes, essays, homework)
 * Handles all type of commands (formatting, flashcards, bullet points, etc.)
 * @param editor
 * @param noteType
 * @param commandId
 * @param content
 * @param options
 * @returns
 */

export const removeLoadingState = () => {
  const wrapper = document.querySelector('.ProseMirror');
  if (wrapper) {
    wrapper.classList.remove('loading_state');
  }
};

export function executeSlashCommand(
  userId: number,
  editor: Editor,
  noteType: string,
  commandId: string,
  content: Record<string, any>,
  options: {
    beforeContent?: boolean;
    afterContent?: boolean;
  } = {}
) {
  return async (dispatch: Dispatch) => {
    // Constants and initial setup
    const { beforeContent, afterContent } = options;
    const fullContentReplaceCommands = [
      'improveWriting',
      'rewriteContent',
      'fixSpelling',
      'rephrase',
    ];
    let accumulatedContent = '';
    console.log('Executing slash command:', commandId);
    console.log('this is the user from executeSlashCommand: ', userId);

    const addLoadingState = () => {
      dispatch(slice.actions.startSlashLoading());
      const wrapper = document.querySelector('.ProseMirror');
      if (wrapper) {
        wrapper.classList.add('loading_state');
      }
    };

    addLoadingState();

    // Helper functions
    const handleError = (error: any) => {
      const errorMessage =
        error?.errors?.json?._schema?.[0] ||
        error?.errors?.json?.[Object.keys(error?.errors.json)[0]] ||
        error?.message ||
        'An error occurred';
      dispatch(slice.actions.hasError(errorMessage));
      dispatch(slice.actions.stopSlashLoading());
      return false;
    };

    const handleStreamMessage = (message: StreamMessage, editor: Editor) => {
      dispatch(slice.actions.stopSlashLoading());
      const { from } = editor.state.selection;

      switch (message.type) {
        case 'hint':
        case 'exercise':
        case 'solution':
        case 'paragraph':
        case 'bullet':
        case 'expansion':
        case 'summary':
        case 'custom':
        case 'improved':
        case 'partial':
        case 'fixed':
        case 'rewritten':
        case 'flashcard':
        case 'continuation':
          console.log('Stream message:', message);
          if (message.replace || fullContentReplaceCommands.includes(commandId)) {
            if (message.html || message.text) {
              accumulatedContent += message.html || message.text;
              console.log('Accumulated content:', accumulatedContent);
              dispatch(slice.actions.startStreamingContent());
              editor.commands.streamReplace({
                type: message.type,
                html: accumulatedContent,
              });
            }
          } else {
            console.log('we are inserting', message);
            dispatch(slice.actions.startStreamingContent());
            editor.commands.streamInsert({
              ...message,
              cursorPosition: from,
            });
          }
          break;

        case 'error':
          console.error('Stream error:', message.message);
          dispatch(slice.actions.hasError(message.message || 'Streaming error occurred'));
          break;

        case 'done':
          console.log('Stream completed');
          dispatch(slice.actions.stopStreamingContent());
          accumulatedContent = '';
          break;

        default:
          console.warn('Unknown message type:', message);
      }
    };

    try {
      // Initial validation
      console.log('Initial validation');
      if (!editor) return false;

      const {
        notes: { note },
      } = store.getState();
      if (!note?.id) throw new Error('No active note found');

      // Prepare request content
      const requestContent = { ...content };

      // Handle cursor position content
      if (beforeContent || afterContent || commandId === 'keepGenerating') {
        const { from } = editor.state.selection;
        if (beforeContent || commandId === 'keepGenerating') {
          requestContent.before_content = editor.view.dom.innerHTML.slice(0, from);
        }
        if (afterContent || commandId === 'keepGenerating') {
          requestContent.after_content = editor.view.dom.innerHTML.slice(from);
        }
      }

      // Handle special cases
      if (commandId === 'fixSpelling') {
        requestContent.content = editor.getHTML();
      }

      // Handle non-streaming commands
      //  if (commandId === 'generateFlashcards') {
      //    const response = await axios.post(`/api/ai/slash-command/${noteType}`, {
      //      command_id: commandId,
      //      note_id: note.id,
      //      content: requestContent,
      //    });

      //  if (commandId === 'generateFlashcards' && Array.isArray(response.data.content)) {
      //    response.data.content.forEach((card: { question: string; answer: string }) => {
      //      if (card.question && card.answer) {
      //        editor.commands.setFlashcard(card.question, card.answer);
      //      }
      //    });
      //  }

      //    dispatch(slice.actions.stopSlashLoading());
      //    return response.data;
      //  }
      const BASE_URL = process.env.REACT_APP_HOST_API_KEY;
      // Handle title generation separately
      // Handle title generation separately
      if (commandId === 'generateTitle') {
        addLoadingState(); // Add loading state at start

        const response = await fetch(`${BASE_URL}/api/ai/slash-command/${noteType}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          body: JSON.stringify({
            command_id: commandId,
            note_id: note.id,
            content: requestContent,
            user_id: userId,
          }),
        });

        const reader = response.body!.getReader();
        const decoder = new TextDecoder();
        const titleWords: string[] = [];

        try {
          let isDone = false;
          while (!isDone) {
            console.log('Reading stream');
            const { done, value } = await reader.read();
            if (done) {
              isDone = true;
              break;
            }

            const chunk = decoder.decode(value);
            const lines = chunk.split('\n').filter((line) => line.trim());
            console.log('Lines:', lines);
            for (const line of lines) {
              try {
                const message: StreamMessage = JSON.parse(line);
                if (message.type === 'title_word' && message.html) {
                  titleWords.push(message.html);
                }
              } catch (e) {
                console.error('Failed to process stream message:', e);
              }
            }
          }
        } catch (error) {
          removeLoadingState(); // Remove loading state on error
          throw error; // Re-throw to be caught by outer try-catch
        } finally {
          reader.releaseLock();
          removeLoadingState(); // Remove loading state when done
        }

        dispatch(slice.actions.stopSlashLoading());
        console.log('This is what we got for title words:', titleWords);
        return { type: 'title', words: titleWords };
      }

      // Handle streaming responses
      const response = await fetch(`${BASE_URL}/api/ai/slash-command/${noteType}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          command_id: commandId,
          note_id: note.id,
          content: requestContent,
          user_id: userId,
        }),
      });

      const reader = response.body!.getReader();
      const decoder = new TextDecoder();

      try {
        let isDone = false;
        while (!isDone) {
          const { done, value } = await reader.read();
          if (done) {
            isDone = true;
            break;
          }

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n').filter((line) => line.trim());
          console.log('Lines:', lines);

          for (const line of lines) {
            try {
              const message: StreamMessage = JSON.parse(line);
              handleStreamMessage(message, editor);
            } catch (e) {
              console.error('Failed to process stream message:', e);
            }
          }
        }
      } finally {
        reader.releaseLock();
      }

      // dispatch(slice.actions.stopSlashLoading());
      removeLoadingState();
      return true;
    } catch (error) {
      return handleError(error);
    }
  };
}

/**
 * Handles AI text operations in the TipTap editor with two modes: generate and stream
 *
 * @param editor - TipTap editor instance
 * @param action - Type of AI action to perform (e.g., 'enhance', 'translate')
 * @param userUid - Optional user identifier
 * @param actionType - Mode of operation ('generate' or 'stream')
 * @param customPrompt - Optional custom instructions for AI
 */

export const handleAiTextOperation = (
  editor: Editor,
  action: string,
  userUid?: string,
  actionType?: string,
  customPrompt?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      // Get selected text and validate selection
      dispatch(slice.actions.startAiActionLoading());
      const selection = getSelectedText(editor);
      if (typeof selection === 'string') return;

      const { text: selectedText, from, to, fullLine, parentDepth } = selection;

      // Get parent node information for loading state
      const pos = editor.state.selection.$from;
      const parentStart = pos.start(parentDepth);
      const parentNode = pos.node(parentDepth);

      editor
        .chain()
        .focus()
        .setNodeSelection(parentStart)
        .updateAttributes(parentNode.type.name, { class: 'loading_state' })
        .setTextSelection({ from, to })
        .run();

      // State variables for streaming mode
      let isFirstChunk = true;
      let previousContent = '';
      let bufferTimeout: NodeJS.Timeout;

      editor.commands.setTextSelection({
        from: editor.state.selection.to,
        to: editor.state.selection.to,
      });

      if (actionType === 'generate') {
        // Generate mode: Used for complex content or HTML formatting
        // Waits for complete response before insertion
        const response = await processAiInsert({
          selectedText,
          context: fullLine,
          action,
          userUid,
          customPrompt,
          onInsertData: (data) => {
            dispatch(slice.actions.stopAiActionLoading());
            const sanitizedContent = sanitizeContent(data);
            const { to } = editor.state.selection;

            // Insert complete content at cursor position
            editor
              .chain()
              .focus()
              .setTextSelection(to)
              .insertContent(sanitizedContent, {
                parseOptions: { preserveWhitespace: false },
              })
              .run();
          },
        });
      } else if (actionType === 'stream') {
        // Stream mode: Used for real-time content insertion
        // Shows content being typed in real-time
        const response = await processAiStream({
          selectedText,
          context: fullLine,
          action,
          userUid,
          customPrompt,
          onStreamData: (data) => {
            dispatch(slice.actions.stopAiActionLoading());
            // Debounce updates to prevent too frequent DOM updates
            if (bufferTimeout) {
              clearTimeout(bufferTimeout);
            }

            bufferTimeout = setTimeout(() => {
              const sanitizedContent = sanitizeContent(data);

              // Handle streaming content insertion
              const result = handleStreamingContent(editor, sanitizedContent, {
                previousContent,
                isFirstChunk,
                from,
                to,
                parentDepth,
              });

              previousContent = result.content;
              isFirstChunk = false;
            }, 50);
          },
        });
      }

      // Remove loading state after successful completion
      if (parentNode) {
        editor
          .chain()
          .focus()
          .setNodeSelection(parentStart)
          .updateAttributes(parentNode.type.name, { class: null })
          .run();
      }
    } catch (error) {
      // Error handling: Remove loading state and log error
      const pos = editor.state.selection.$from;
      const parentDepth = pos.depth;
      const parentStart = pos.start(parentDepth);
      const parentNode = pos.node(parentDepth);

      editor
        .chain()
        .focus()
        .setNodeSelection(parentStart)
        .updateAttributes(parentNode.type.name, { class: null })
        .run();
      console.error(`Error processing AI ${action} request:`, error);
    }
  };
};
