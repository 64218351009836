import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => {
  const LoadableComponent = (props: any) => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

  LoadableComponent.displayName = 'Loadable'; // Set displayName property
  return LoadableComponent;
};

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const ConfirmationPage = Loadable(lazy(() => import('../pages/auth/ConfirmationPage')));
export const FreemiumConfirmationPage = Loadable(
  lazy(() => import('../pages/auth/FreemiumConfirmationPage'))
);

export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const ForgotPasswordPage = Loadable(lazy(() => import('../pages/auth/ForgotPasswordPage')));
export const Thanks = Loadable(lazy(() => import('../pages/auth/Thanks')));
// Dashboard
export const PageDashboard = Loadable(lazy(() => import('../pages/DashboardPage')));
// AI
export const AiPage = Loadable(lazy(() => import('../pages/dashboard/ai_helper/AiPage')));
export const AiEmptyPage = Loadable(
  lazy(() => import('../pages/dashboard/ai_helper/AiPageSignUp'))
);

// Note Editor
export const NoteHomePage = Loadable(lazy(() => import('../pages/dashboard/notes/NoteHomePage')));
export const NoteEditorPage = Loadable(
  lazy(() => import('../pages/dashboard/notes/DocumentEditorPage'))
);

// Membership
export const MembershipPage = Loadable(
  lazy(() => import('../pages/dashboard/membership/MembershipPage'))
);
export const CustomerMembershipPage = Loadable(
  lazy(() => import('../pages/dashboard/membership/CustomerMembershipPage'))
);
export const MembershipConfirmationPage = Loadable(
  lazy(() => import('../pages/dashboard/membership/MembershipConfirmationPage'))
);
export const ViewMembershipPage = Loadable(
  lazy(() => import('../pages/dashboard/membership/ViewMembershipPage'))
);
export const SwitchMembershipPage = Loadable(
  lazy(() => import('../pages/dashboard/membership/SwitchMembershipPage'))
);
export const SwitchMembershipPageSlider = Loadable(
  lazy(() => import('../pages/dashboard/membership/SwitchMembershipPageSlider'))
);

// Students
export const PageStudentList = Loadable(
  lazy(() => import('../pages/dashboard/student/StudentListPage'))
);
export const StudentProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/student/StudentProfilePage'))
);
export const StudentCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/student/StudentCreatePage'))
);
export const StudentTeachersPage = Loadable(
  lazy(() => import('../pages/dashboard/student/StudentTeachersPage'))
);

// Tutors
export const PageTutorList = Loadable(lazy(() => import('../pages/dashboard/tutor/TutorListPage')));
export const PageTutorCourses = Loadable(
  lazy(() => import('../pages/dashboard/tutor/TutorCoursesPage'))
);
export const TutorCoursesDetails = Loadable(
  lazy(() => import('../pages/dashboard/tutor/TutorCourseDetails'))
);
export const TutorProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/tutor/TutorProfilePage'))
);
export const TutorCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/tutor/TutorCreatePage'))
);
export const TutorStudentsPage = Loadable(
  lazy(() => import('../pages/dashboard/tutor/TutorStudentsPage'))
);
export const TutorEarningsPage = Loadable(
  lazy(() => import('../pages/dashboard/tutor/TutorEarningsPage'))
);
// Customers
export const PageCustomerList = Loadable(
  lazy(() => import('../pages/dashboard/customer/CustomerListPage'))
);
export const CustomerProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/customer/CustomerProfilePage'))
);
export const CustomerCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/customer/CustomerCreatePage'))
);
export const CustomerTeachersPage = Loadable(
  lazy(() => import('../pages/dashboard/customer/CustomerTeachersPage'))
);
export const CustomerStudentsPage = Loadable(
  lazy(() => import('../pages/dashboard/customer/CustomerStudentsPage'))
);
// Transactions
export const PageCustomerTransactionsList = Loadable(
  lazy(() => import('../pages/dashboard/transactions/CustomerTransactionsListPage'))
);
export const PageTransactionList = Loadable(
  lazy(() => import('../pages/dashboard/transactions/TransactionsListPage'))
);
export const PageViewTransaction = Loadable(
  lazy(() => import('../pages/dashboard/transactions/ViewTransactionPage'))
);
export const PageEditCreateTransaction = Loadable(
  lazy(() => import('../pages/dashboard/transactions/EditCreateTransactionPage'))
);
// Invoices
export const PageInvoiceList = Loadable(
  lazy(() => import('../pages/dashboard/invoice/InvoiceListPage'))
);
export const PageInvoice = Loadable(
  lazy(() => import('../pages/dashboard/invoice/ViewInvoicePage'))
);
export const PayInvoicePage = Loadable(
  lazy(() => import('../pages/dashboard/invoice/PayInvoicePage'))
);
export const PageCreateEditInvoice = Loadable(
  lazy(() => import('../pages/dashboard/invoice/EditCreateInvoicePage'))
);
export const PageCreateCustomInvoice = Loadable(
  lazy(() => import('../pages/dashboard/invoice/CreateCustomInvoicePage'))
);
export const PageCustomerInvoicesList = Loadable(
  lazy(() => import('../pages/dashboard/invoice/CustomerInvoiceListPage'))
);
// Calendar
export const PageCalendar = Loadable(
  lazy(() => import('../pages/dashboard/calendar/CalendarPage'))
);
// Lessons
export const PageLessonsList = Loadable(
  lazy(() => import('../pages/dashboard/lessons/LessonsListPage'))
);
export const LessonPage = Loadable(lazy(() => import('../pages/dashboard/lessons/LessonPage')));
export const LessonDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/lessons/LessonDetailsPage'))
);
// Lesson Requests
export const LessonRequestsPage = Loadable(
  lazy(() => import('../pages/dashboard/lessonrequests/LessonRequestsPage'))
);
//report
export const StudentReportPage = Loadable(
  lazy(() => import('../pages/dashboard/student/StudentReportPage'))
);

//email
export const SendEmailPage = Loadable(lazy(() => import('../pages/dashboard/email/SendEmailPage')));

// Payslips
export const PagePayslipsList = Loadable(
  lazy(() => import('../pages/dashboard/payslip/PayslipListPage'))
);
export const PagePayslipDetails = Loadable(
  lazy(() => import('../pages/dashboard/payslip/PayslipDetailsPage'))
);
// Balances
export const PageBalancesList = Loadable(
  lazy(() => import('../pages/dashboard/balance/BalanceListPage'))
);
export const PageBalance = Loadable(
  lazy(() => import('../pages/dashboard/balance/ViewBalancePage'))
);
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

//Leaderboard
// export const PageLeaderboard = Loadable(
//   lazy(() => import('../pages/dashboard/leaderboard/LeaderboardPage'))
// );
//Badge
// export const PageBages = Loadable(lazy(() => import('../pages/dashboard/leaderboard/BadgesPage')));

export const PageParticipantDetails = Loadable(
  lazy(() => import('../pages/dashboard/participant/ParticipantDetailsPage'))
);

// Chat
// export const PageChat = Loadable(lazy(() => import('../pages/dashboard/chat/ChatPage')));
